import React, { FC, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RouteComponentProps } from '@reach/router';
import styled from 'styled-components';

import { LoadingPage, Seo } from 'components';
import { Analytics } from 'apis/Analytics';
import { AppState } from 'state/types';
import HowToSection from './components/HowToSection';
import ThankYouSection from './components/ThankYouSection';
import OrderSummary from './components/OrderSummary';
import Header from 'components/Header';
import { usePageView, useQuizData } from 'utils/hooks';
import Tracking from 'utils/tracking';
import { normalizeStates } from 'utils/localization';

const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const Success: FC<RouteComponentProps> = () => {
  const code = useSelector((state: AppState) => state.user.code);
  const data = useQuizData('success');
  const user = useSelector((state: AppState) => state.user);

  usePageView({
    country:
      user.geolocation?.iso_country?.toLowerCase() || 'no-country-fallback',
    state: normalizeStates(
      user.geolocation?.iso_country || '',
      user.geolocation?.iso_state || '',
    ),
    city: encodeURIComponent(
      user.geolocation?.city?.toLowerCase().replace(/[^a-z0-9]/g, '') || '',
    ),
    email: user.user?.email.trim() || 'no-email-fallback',
    gender: user.quiz_answers?.gender ? user.quiz_answers?.gender[0] : null,
    client_code: code,
  });

  useEffect(() => {
    if (!code) {
      return;
    }

    Tracking.logEvent({
      event: 'email_left',
      client_code: code,
    });
  }, [code]);

  if (!data) {
    return <LoadingPage />;
  }

  return (
    <>
      <Seo />
      <Header logoVariant="left" color="light0" />
      <Container>
        <ThankYouSection {...data?.section1} />
        <HowToSection {...data?.section2} />
        <OrderSummary {...data?.section3} />
      </Container>
    </>
  );
};

export default Success;
